import Header from "./Components/Header";
import Footer from "./Components/Footer";
import HomePage from "./Pages/HomePage";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Eco4Funding from "./Pages/Eco4Funding";
import ElectricHeating from "./Pages/ElectricHeating";
import CavityWall from "./Pages/CavityWall";
import Faq from "./Pages/faq";
import AboutUs from "./Pages/About-Us";
import ContactUs from "./Pages/ContactUs";
import AirSource from "./Pages/AirSource";
import LoftInsulation from "./Pages/LoftInsulation";
import CavityWallExtraction from "./Pages/CavityWallExtraction";
import CertifiedInsulationInstallers from "./Pages/CertifiedInsulationInstallers";
import SmartControls from "./Pages/SmartControls";
import EcoHeating from "./Pages/EcoHeating";
import Flexibility from "./Pages/Flexibility";
import FreeHeatingGrants from "./Pages/FreeHeatingGrants";
import SolarEnergy from "./Pages/SolarEnergy";
import SolidWallInsulation from "./Pages/SolidWallInsulation";
import StorageHeaters from "./Pages/StorageHeaters";
import Ventilation from "./Pages/Ventilation";
import QualityMarkProtection from "./Pages/QualityMarkProtection";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <>
      <Routes>
        {/* <Header /> */}
        <Route path="/" element={<HomePage />} />
        <Route path="/eco-4" element={<Eco4Funding />} />
        <Route path="/air-source-heating" element={<ElectricHeating />} />
        <Route path="/external-wall" element={<CavityWall />} />
        <Route path="/smart-controls" element={<SmartControls />} />
        <Route path="/faqs" element={<Faq />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/air-source-heat-pumps" element={<AirSource />} />
        <Route path="/loft-insulation" element={<LoftInsulation />} />
        <Route
          path="/cavity-wall-extraction"
          element={<CavityWallExtraction />}
        />
        <Route
          path="/certified-insulation-installers"
          element={<CertifiedInsulationInstallers />}
        />
        {/* <Route path="/eco-heating" element={<EcoHeating />} /> */}
        <Route path="/flexible-eligibility" element={<Flexibility />} />
        {/* <Route path="/free-heating-grants" element={<FreeHeatingGrants />} /> */}
        <Route path="/solar-energy" element={<SolarEnergy />} />
        <Route
          path="/solid-wall-insulation"
          element={<SolidWallInsulation />}
        />
        {/* <Route path="/storage-heaters" element={<StorageHeaters />} /> */}
        <Route path="/ventilation" element={<Ventilation />} />
        <Route
          path="/qualitymark-protection"
          element={<QualityMarkProtection />}
        />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
