let BASE_URL = "https://epc.opendatacommunities.org/api/v1/domestic/search";
let token =
  "YWhtYWRjaDYyOEBnbWFpbC5jb206ZTI4Y2U5MmEwODJkMmJkZDBhMzUwM2Q2OGIzOTlhNzQzYzJjZjU0Zg==";
let  carouselConfig = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
module.exports = {
  BASE_URL,
  token,
  carouselConfig,
};
