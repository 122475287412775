import React from "react";
import CarouselComponent from "../Components/CarouselComponent";
import Header from "../Components/Header";
import { useDocumentTitle } from "../Util/hooks/useDocumentTitle";

function Ventilation(props) {
  useDocumentTitle("Ventilation");
  return (
    <div>
      <div class="header-bg">
        <div class="navbar-section-ventibg">
          <Header />
          <section class="banner-section">
            <div class="container">
              <div class="row">
                <div class="col-lg-7 col-md-12 col-sm-12">
                  <h1 class="header-txt" data-aos="fade-up">
                    Ventilation
                  </h1>
                  <div class="qabout-faq">
                    <div class="row">
                      <div class="col-md-3">
                        <div class="qaboutbtn">
                          <a href="/faqs" class="qabout-btn">
                            FAQs
                          </a>
                        </div>
                      </div>
                      <div class="col-md-9">
                        <h4>
                          Got a question first?
                          <br />
                          Visit out FAQs page
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 mt-3">
                  <div class="qa-faq" data-aos="fade-down">
                    <h2 class="fdaont_2">
                      How efficient is
                      <br />{" "}
                      <span>
                        <span class="green-light">YOUR</span>
                      </span>{" "}
                      home?
                    </h2>
                    <div class="company_btn_wrapper">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        class="text-decoration-none learn_btn_company_stats"
                        href="https://docs.google.com/forms/d/e/1FAIpQLSd4e33mW5w0Uk_ySZ3OKX0XxS_6eh_fuawc6_bVU6xe-JRgtQ/viewform?usp=sf_link"
                      >
                        CHECK ELIGIBILITY
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <section class="step-sec-about mt-3">
        <div class="container ">
          <div class="row">
            <div class="col-md-12">
              <div class="step-box">
                <h2 class="green-light">THREE SIMPLE STEPS:</h2>
                <div class="row">
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon1.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        Take 60 seconds of your <br />
                        time to fill out the form
                      </h5>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon2.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        We’ll let you know whether <br />
                        you’re eligible for a grant
                      </h5>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon3.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        Our engineers will be in touch to <br />
                        book a survey and installation date
                      </h5>
                    </div>
                  </div>
                </div>
                <span>
                  No more days and nights spent battling the cold. Keep your
                  home nice and cosy and keep those energy bills low.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="about_us"
        style={{ backgroundColor: "#fff", paddingBottom: 0 }}
      >
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <img src="assets/images/venti1.webp" width="100%" alt="" />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_content">
                <h3 class="fsont__3" data-aos="fade-right">
                  <span>
                    <span>Why is ventilation </span>
                    <span class="green-light">so important?</span>
                  </span>
                </h3>

                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  Homes in the UK have traditionally relied on wind-driven
                  airflow to provide proper ventilation, i.e., to exhaust
                  "stale" air (including contaminants like moisture and high
                  concentrations of carbon dioxide) and to supply a supply of
                  "fresh" (i.e., external) air.
                </p>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  Since the 1970s, the installation of double-glazed,
                  draught-stripped windows, the draught-stripping of exterior
                  doors, and the insulation of lofts and cavity walls have
                  improved energy efficiency while simultaneously lowering the
                  air permeability of many homes, reducing air leakage and
                  infiltration.
                </p>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  In some instances, this has been made up for by installing
                  background ventilators (air inlets, also known as trickle
                  ventilators) in other spaces to provide balancing supplies of
                  fresh air. "Wet" spaces, such as kitchens and bathrooms, have
                  intermittent extract ventilation fans installed to remove
                  moist, stale air. However, the presence of a ventilation
                  system does not necessarily mean that a building is
                  effectively ventilated, and many older structures are not.
                </p>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  When a home is made more airtight to limit the amount of
                  wind-driven air coming in and going out, along with having
                  enough controlled ventilation, the energy efficiency is
                  greatly increased.
                </p>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  The addition of any additional insulation, however, reduces
                  the rate of airflow into and out of the building without
                  sufficient ventilation, increasing the risk of high relative
                  humidity (which raises the possibility of condensation and the
                  growth of mould), high concentrations of pollutants like
                  carbon dioxide, and dust mites. All of these factors may raise
                  major health concerns for building occupants or increase the
                  danger of finishes, fabric, and structural damage.
                </p>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  The effectiveness of the present ventilation must be evaluated
                  and, if necessary, increased before adding any insulating
                  measures.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="about_us"
        style={{ backgroundColor: "#fff", paddingBottom: 0 }}
      >
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_content">
                <h3 class="fsont__3" data-aos="fade-right">
                  <span>
                    <span>Have I have </span>
                    <span class="green-light">enough ventilation?</span>
                  </span>
                </h3>

                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  Installing a suitable, comprehensive ventilation system is
                  required in order to claim financing for any insulation. It
                  must be one of the following:
                </p>
                <ul class="font_7">
                  <li>
                    <p class="font_7">
                      an intermittent extract ventilation (IEV) system made up
                      of appropriately sized background ventilators (to admit
                      "fresh" outside air) in all living areas and bedrooms and
                      extract fans in all "wet" rooms;
                      <br />
                      or
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      a passive stack ventilation (PSV) system that includes
                      background ventilators of the proper size in all living
                      areas and bedrooms to allow "fresh" outside air, as well
                      as passive stack ventilators serving all "wet" rooms;
                      <br />
                      or
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      a continuous positive input ventilation (PIV) system that
                      provides "fresh" air, together with appropriately sized
                      background ventilators (to let wet "stale" air out), in
                      each living area and each bedroom;
                      <br />
                      or
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      a mechanical extract ventilation (MEV) system that
                      continuously removes damp, "stale" air from all "wet"
                      rooms in conjunction with appropriately sized background
                      ventilators (to admit "fresh" outside air) in all living
                      areas and bedrooms;
                      <br />
                      or
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      a fully installed, properly commissioned, and balanced
                      whole-house supply and extract MVHR system that delivers
                      "fresh" everlasting air to all living areas and bedrooms
                      while extracting moist, "stale" air from all wet rooms.
                    </p>
                  </li>
                </ul>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  There won't be any further ventilation work needed if you
                  currently have one of the aforementioned systems and it is
                  determined to be acceptable during the survey.
                </p>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  However, if you don't or the current ventilation system is
                  deemed insufficient, we will also need to install extra
                  ventilation solutions in your home in addition to the
                  insulating work.
                </p>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  We will attach air-box vents to openable windows in living
                  spaces that are not considered "wet rooms." These vents take
                  about 5 minutes to install, don't require drilling, and are
                  installed from the inside. Absolutely regulated air flow in
                  and out, no mess, no damage.
                </p>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  We will install extractor fans of the correct size and
                  configuration in "wet rooms" to remove "stale" moist interior
                  air from your property.{" "}
                </p>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <img src="assets/images/venti2.webp" width="100%" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section
        class="about_us"
        style={{ backgroundColor: "#fff", paddingBottom: 0 }}
      >
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <img src="assets/images/venti3.webp" width="100%" alt="" />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_content">
                <h3 class="fsont__3" data-aos="fade-right">
                  <span>
                    <span>Air-Box® </span>
                    <span class="green-light">air valves</span>
                  </span>
                </h3>
                <strong>Why fit them?</strong>

                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  The first clue that the indoor environment is failing is
                  condensation on windows; higher humidity encourages the growth
                  of fungi, bacteria, and mould. Typically, inadequate
                  ventilation is to blame.
                  <br />
                  Fungi and mould pose health risks. Allergy-related cough,
                  bronchitis, headache, nausea, and other unpleasant symptoms
                  are brought on by fungus spores.
                  <br />
                  Heat loss is inextricably linked to ventilation. Heat loss
                  through open windows happens 10 times more quickly than with
                  the Air-Box system®.
                  <br />
                  According to the norm LBN 231-15, "Heating and ventilation of
                  residential and public buildings," the Air-Box system® enables
                  air exchange. The technology is very practical since it can be
                  quickly installed without requiring the window profile to be
                  cut. In the Air-Box system® are
                </p>
                <ul class="font_7">
                  <li>
                    <p class="font_7">noise mitigation</p>
                  </li>
                  <li>
                    <p class="font_7">
                      cleansing of entering air to remove spores, soot, dust,
                      and pollen.
                    </p>

                    <ul class="font_7">
                      <li>
                        <p class="font_7">
                          In locations where special sealing rubbers are used in
                          place of ordinary ones, fresh air enters the channel
                          between the frame and sash.
                        </p>
                      </li>
                      <li>
                        <p class="font_7">
                          The adjuster handle, which controls air flow, makes
                          sure that the air supply is adjusted smoothly and
                          evenly.
                        </p>
                      </li>
                    </ul>
                  </li>
                </ul>

                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  Fresh air reduces the occurrence of allergic reactions and
                  other diseases. From a medical point of view, the Air-Box
                  system® ideal for young children, the elderly, people with
                  allergies and asthma.
                  <br />
                  Additionally, the system minimises heat loss and significantly
                  lowers your heating costs.
                </p>
              </div>
              <div class="row accordian-section">
                <div class="col-md-12">
                  <div class="faqhead">
                    <h5 class="text-center green-dark">
                      Frequently asked questions
                    </h5>
                    <h3 class="green-light">FAQs</h3>
                  </div>
                </div>
                <div class="col-lg-12 mt-2" data-aos="fade-right">
                  <div class="about-service-section-text accordian-section-inner-text position-relative">
                    <div class="accordian-inner">
                      <div id="accordion1">
                        <div class="accordion-card">
                          <div class="card-header" id="headingFour">
                            <button
                              class="btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#collapseFour"
                              aria-expanded="false"
                              aria-controls="collapseFour"
                            >
                              Is this legit? How can it be free?
                            </button>
                          </div>
                          <div
                            id="collapseFour"
                            class="collapse"
                            aria-labelledby="headingFour"
                          >
                            <div class="card-body">
                              <p class="text-left mb-0 p-0">
                                It is free as a result of a government energy
                                efficiency programme that is intended to combat
                                fuel poverty and lower carbon emissions
                                throughout Great Britain. Under the ECO3 and
                                ECO4 programmes, medium and large energy
                                suppliers (ECO stands for "Energy Company
                                Obligation") are required to satisfy targets and
                                provide funding for the installation of
                                energy-efficient technologies in British houses.
                                <br />
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-card">
                          <div class="card-header" id="headingFive">
                            <button
                              class="btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#collapseFive"
                              aria-expanded="false"
                              aria-controls="collapseFive"
                            >
                              Who are Princeton Eco Plus Ltd?
                            </button>
                          </div>
                          <div
                            id="collapseFive"
                            class="collapse"
                            aria-labelledby="headingFive"
                          >
                            <div class="card-body">
                              <p class="text-left mb-0 p-0">
                                We work with energy companies in Great Britain
                                to find people like you who are eligible for
                                energy efficient upgrades. We take your details
                                and check your eligibility to this government
                                backed scheme. There is no cost to you for using
                                our services and it’s free to find out if you
                                qualify or not.
                                <br />
                                Once you complete the eligibility form, a member
                                of our team will contact you to discuss all the
                                options available to you. You can then decide
                                which measures you want installing; there is no
                                obligation.
                                <br />
                                Once you’re approved, we work around you to
                                install the measures you have chosen.
                                <br />
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-card">
                          <div class="card-header" id="headingSix">
                            <button
                              class="btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#collapseSix"
                              aria-expanded="false"
                              aria-controls="collapseSix"
                            >
                              Do I have a qualifying household?
                            </button>
                          </div>
                          <div
                            id="collapseSix"
                            class="collapse"
                            aria-labelledby="headingSix"
                          >
                            <div class="card-body">
                              <p class="text-left mb-0 p-0">
                                To qualify for an electric storage heater grant
                                you must be living in private accomodation that
                                is for domestic use.
                                <br />
                                These grants are not available if you live in
                                Council or Housing Association accommodation.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-card">
                          <div class="card-header" id="headingTen">
                            <button
                              class="btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#collapseTen"
                              aria-expanded="false"
                              aria-controls="collapseTen"
                            >
                              What type of heating qualifies?
                            </button>
                          </div>
                          <div
                            id="collapseTen"
                            class="collapse"
                            aria-labelledby="headingTen"
                          >
                            <div class="card-body">
                              <p class="text-left mb-0 p-0">
                                If your electric storage heaters are 10+ years
                                old or perhaps one (or several) are faulty, then
                                you should apply for an electric storage heater
                                grant. We can organise high energy efficiency
                                units to be installed by fully accredited
                                partners.
                                <br />
                                If you use standard electric heaters or fan
                                heaters to heat your property then you should
                                apply for this free grant as we can replace your
                                current heaters with new storage heaters.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-card">
                          <div class="card-header" id="headingSeven">
                            <button
                              class="btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#collapseSeven"
                              aria-expanded="false"
                              aria-controls="collapseSeven"
                            >
                              What benefits qualify for a grant?
                            </button>
                          </div>
                          <div
                            id="collapseSeven"
                            class="collapse"
                            aria-labelledby="headingSeven"
                          >
                            <div class="card-body">
                              <p class="text-left mb-0 p-0">
                                To qualify for funding, someone living in the
                                property needs to be in receipt of at least one
                                of the benefits listed below:
                                <ul>
                                  <li>Armed Forces Independence Payment</li>
                                  <li>Attendance Allowance</li>
                                  <li>Carer’s Allowance</li>
                                  <li>Child Benefit*</li>
                                  <li>Child Tax Credit</li>
                                  <li>Constant Attendance Allowance</li>
                                  <li>Disability Living Allowance</li>
                                  <li>Income-Based Jobseekers Allowance</li>
                                  <li>
                                    Income-Related Employment Support Allowance
                                  </li>
                                  <li>Income Support</li>
                                  <li>
                                    Industrial Injuries Disablement Benefit
                                  </li>
                                  <li>Pension Guarantee Credit</li>
                                  <li>Personal Independence Payment (PIP)</li>
                                  <li>Severe Disablement Allowance</li>
                                  <li>Universal Credit</li>
                                  <li>War Pensions Mobility Supplement</li>
                                  <li>Working Tax Credit</li>
                                  <li>Household income limits apply</li>
                                </ul>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-card">
                          <div class="card-header" id="headingEight">
                            <button
                              class="btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#collapseEight"
                              aria-expanded="false"
                              aria-controls="collapseEight"
                            >
                              Why do you need my personal information?
                            </button>
                          </div>
                          <div
                            id="collapseEight"
                            class="collapse"
                            aria-labelledby="headingEight"
                          >
                            <div class="card-body">
                              <p class="text-left mb-0 p-0">
                                We need to validate your eligibility to the
                                scheme by contacting the DWP (Department for
                                Work & Pensions) or speak to your local council
                                in the event you don’t automatically qualify
                                (see flexible eligibility).
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-card">
                          <div class="card-header" id="headingNine">
                            <button
                              class="btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#collapseNine"
                              aria-expanded="false"
                              aria-controls="collapseNine"
                            >
                              Do I get a warranty for work carried out?
                            </button>
                          </div>
                          <div
                            id="collapseNine"
                            class="collapse"
                            aria-labelledby="headingNine"
                          >
                            <div class="card-body">
                              <p class="text-left mb-0 p-0">
                                Yes. All of the work we carry out comes with
                                either a warranty or guarantee. Find out more
                                here.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-card">
                          <div class="card-header" id="headingEleven">
                            <button
                              class="btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#collapseEleven"
                              aria-expanded="false"
                              aria-controls="collapseEleven"
                            >
                              What areas do you cover?
                            </button>
                          </div>
                          <div
                            id="collapseEleven"
                            class="collapse"
                            aria-labelledby="headingEleven"
                          >
                            <div class="card-body">
                              <p class="text-left mb-0 p-0">
                                We have a national installation team, multiple
                                branches and cover the whole of Great Britain.
                              </p>
                            </div>
                          </div>
                        </div>

                        <div class="accordion-card">
                          <div class="card-header" id="headingTwelve">
                            <button
                              class="btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#collapseTwelve"
                              aria-expanded="false"
                              aria-controls="collapseTwelve"
                            >
                              What are heating grants?
                            </button>
                          </div>
                          <div
                            id="collapseTwelve"
                            class="collapse"
                            aria-labelledby="headingTwelve"
                          >
                            <div class="card-body">
                              <p class="text-left mb-0 p-0">
                                Grants mean you do not have to pay for the
                                heating or energy efficiency measures installed.
                                There is nothing to pay either up front or
                                subsequently because it is done by way of a
                                grant, not a loan.
                                <br />
                                These are given as part of the government energy
                                efficiency scheme, designed to tackle fuel
                                poverty and reduce carbon emissions across Great
                                Britain. Under the ECO3 and ECO4 schemes, medium
                                and large energy suppliers have targets to meet
                                (ECO stands for “Energy Company Obligation”) and
                                they fund the installation of energy efficient
                                systems in British households.
                                <br />
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="red-sec">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h5>Are you eligible for funding? Fill out the form.</h5>
            </div>
          </div>
        </div>
      </section>
      <CarouselComponent />
    </div>
  );
}

export default Ventilation;
