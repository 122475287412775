import React from "react";
import CarouselComponent from "../Components/CarouselComponent";
import Header from "../Components/Header";
import { useDocumentTitle } from "../Util/hooks/useDocumentTitle";

function QualityMarkProtection(props) {
  useDocumentTitle("Qualitymark Protection");
  return (
    <div>
      <div class="header-bg">
        <div class="navbar-section-qualitybg">
          <Header />
          <section class="banner-section">
            <div class="container">
              <div class="row">
                <div class="col-lg-7 col-md-12 col-sm-12">
                  <img
                    src="assets/images/logos/quality-mark-logo.png"
                    width="200px"
                    alt=""
                  />
                  <h1 class="header-txt" data-aos="fade-up">
                    Qualitymark Protection
                  </h1>
                  <p class="fontww_7">
                    <span class="color_11">
                      Qualitymark Protection are an insurance broker who are
                      authorised and regulated by the Financial Conduct
                      Authority. They specialise in providing both deposit
                      protection and insurance backed guarantees for our home
                      improvement installations.
                    </span>
                  </p>
                  <div class="qabout-faq">
                    <div class="row">
                      <div class="col-md-3">
                        <div class="qaboutbtn">
                          <a href="/faqs" class="qabout-btn">
                            FAQs
                          </a>
                        </div>
                      </div>
                      <div class="col-md-9">
                        <h4>
                          Got a question first?
                          <br />
                          Visit out FAQs page
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 mt-3">
                  <div class="qa-faq" data-aos="fade-down">
                    <h2 class="fdaont_2">
                      How efficient is
                      <br />{" "}
                      <span>
                        <span class="green-light">YOUR</span>
                      </span>{" "}
                      home?
                    </h2>
                    <div class="company_btn_wrapper">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        class="text-decoration-none learn_btn_company_stats"
                        href="https://docs.google.com/forms/d/e/1FAIpQLSd4e33mW5w0Uk_ySZ3OKX0XxS_6eh_fuawc6_bVU6xe-JRgtQ/viewform?usp=sf_link"
                      >
                        CHECK ELIGIBILITY
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <section class="step-sec-about mt-3">
        <div class="container ">
          <div class="row">
            <div class="col-md-12">
              <div class="step-box">
                <h2 class="green-light">THREE SIMPLE STEPS:</h2>
                <div class="row">
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon1.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        Take 60 seconds of your <br />
                        time to fill out the form
                      </h5>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon2.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        We’ll let you know whether <br />
                        you’re eligible for a grant
                      </h5>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon3.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        Our engineers will be in touch to <br />
                        book a survey and installation date
                      </h5>
                    </div>
                  </div>
                </div>
                <span>
                  No more days and nights spent battling the cold. Keep your
                  home nice and cosy and keep those energy bills low.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="about_us"
        style={{ backgroundColor: "#fff", paddingBottom: 0 }}
      >
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <img src="assets/images/quality1.webp" width="100%" alt="" />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_content">
                <h3 class="fsont__3" data-aos="fade-right">
                  <span>
                    <span>What is an </span>
                    <span class="green-light">
                      Insurance Backed Guarantee?{" "}
                    </span>
                  </span>
                </h3>

                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  If we are unable to uphold our written guarantee, you are
                  given important security by an insurance-backed guarantee.
                </p>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  Our craftsmanship guarantee is covered by the insurance-backed
                  guarantee, which also gives you coverage for the time frame
                  specified. Only Qualitymark Protection Accredited Installers
                  are eligible for the insurance-backed assurances from
                  Qualitymark Protection, which we acquire on your behalf.
                </p>

                <h3 class="fsont__3" data-aos="fade-right">
                  <span>
                    <span>What does it take to become an </span>
                    <span class="green-light">Accredited Installer? </span>
                  </span>
                </h3>

                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  Every potential Accredited Installer must prove that they are
                  knowledgeable, operate a successful business, and only install
                  goods for which they have the necessary training or
                  experience.
                </p>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  As an Accredited Installer, we undergo routine oversight to
                  confirm that we are in good standing financially and that we
                  adhere to the Qualitymark Protection Terms of Business
                  Agreement and Quality Assurance Frameworks.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <iframe
                width="1115"
                height="629"
                src="https://www.youtube.com/embed/1BmRDeE0F8s"
                title="Qualitymark Protection Insurance Backed Guarantee"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </section>
      <section
        class="about_us"
        style={{ backgroundColor: "#fff", paddingBottom: 0 }}
      >
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_content">
                <h3 class="fsont__3" data-aos="fade-right">
                  <span>
                    <span>Why should I select an installer who offers </span>
                    <span class="green-light">
                      insurance backed guarantees?{" "}
                    </span>
                  </span>
                </h3>

                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  You are given added piece of mind by hiring an installer who
                  is looking out for your interests and confidence that you are
                  protected should something unanticipated happen, subject to
                  the terms and circumstances of the insurance-backed guarantee.
                </p>
                <h3 class="fsont__3" data-aos="fade-right">
                  <span>
                    <span>Want to learn more about </span>
                    <span class="green-light">Qualitymark Protection? </span>
                  </span>
                </h3>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  On Qualitymark Protection's website, click here, or phone them
                  directly at 0345 340 0524, you can find out more about the
                  company and the insurance-backed guarantees they offer.
                </p>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <img src="assets/images/quality2.webp" width="100%" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section class="red-sec">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h5>Are you eligible for a grant? Fill in the form.</h5>
            </div>
          </div>
        </div>
      </section>
      <CarouselComponent />
    </div>
  );
}

export default QualityMarkProtection;
