import React from "react";
import CarouselComponent from "../Components/CarouselComponent";
import Header from "../Components/Header";
import { useDocumentTitle } from "../Util/hooks/useDocumentTitle";
function AboutUs(props) {
  useDocumentTitle("About Us");
  return (
    <div>
      <div class="header-bg">
        <div class="navbar-section-aboutinner">
          <Header />
          <section class="banner-section">
            <div class="container">
              <div class="row">
                <div class="col-lg-7 col-md-12 col-sm-12">
                  <h1 class="header-txt" data-aos="fade-up">
                    About Us
                  </h1>
                  <div class="qabout-faq">
                    <div class="row">
                      <div class="col-md-3">
                        <div class="qaboutbtn">
                          <a href="/faqs" class="qabout-btn">
                            FAQs
                          </a>
                        </div>
                      </div>
                      <div class="col-md-9">
                        <h4>
                          Got a question first?
                          <br />
                          Visit out FAQs page
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 mt-5">
                  <div class="qa-faq" data-aos="fade-down">
                    <h2 class="fdaont_2">
                      How efficient is
                      <br />{" "}
                      <span>
                        <span class="green-light">YOUR</span>
                      </span>{" "}
                      home?
                    </h2>
                    <div class="company_btn_wrapper">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        class="text-decoration-none learn_btn_company_stats"
                        href="https://docs.google.com/forms/d/e/1FAIpQLSd4e33mW5w0Uk_ySZ3OKX0XxS_6eh_fuawc6_bVU6xe-JRgtQ/viewform?usp=sf_link"
                      >
                        CHECK ELIGIBILITY
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <section class="step-sec-about mt-3">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="step-box">
                <h2 class="green-dark">THREE SIMPLE STEPS:</h2>
                <div class="row">
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon1.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        Take 60 seconds of your <br />
                        time to fill out the form
                      </h5>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon2.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        We’ll let you know whether <br />
                        you’re eligible for a grant
                      </h5>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon3.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        Our engineers will be in touch to <br />
                        book a survey and installation date
                      </h5>
                    </div>
                  </div>
                </div>
                <span>
                  No more days and nights spent battling the cold. Keep your
                  home nice and cosy and keep those energy bills low.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="about_us"
        style={{ backgroundColor: "#fff", paddingBottom: 0 }}
      >
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <img
                src="assets/images/loft-insulation.jpg"
                width="100%"
                alt=""
              />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_content">
                <h3 class="fsont__3" data-aos="fade-right">
                  <span>
                    <span>About </span>
                    <span class="green-light">Us</span>
                  </span>
                </h3>

                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  A national company, Princeton Eco Plus Ltd., specialises in
                  the ECO sector and provides subsidised measures for qualified
                  consumers across the country
                </p>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  The Energy Company Obligation (ECO) enables Princeton Eco Plus
                  Ltd. to increase energy efficiency for consumers through a
                  variety of actions that genuinely raise living standards while
                  consuming less energy.
                </p>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  The ECO initiative, which was managed by Ofgem, which is still
                  the regulating organisation, aims to improve energy efficiency
                  across Great Britain.
                </p>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  As we work to end a national rising problem that impacts so
                  many people every day, tackling fuel poverty is a significant
                  area of focus for us.
                </p>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  Princeton Eco Plus Ltd. has consistently been a success story
                  in the UK and has given the neighbourhood community
                  opportunities in addition to offering work and job stability.
                  We continue to have a beneficial impact on the standard of
                  living across the country as we meet our financial partners'
                  targets for delivery.
                </p>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  These are the explanations behind our actions.{" "}
                </p>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  We are dedicated to doing everything in our power to provide
                  savings across the country, and with the help of our staff, we
                  are well-equipped to handle projects from start to finish with
                  a genuine focus on customer experience.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="red-sec">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h5>Fill in the form to check your eligibility.</h5>
            </div>
          </div>
        </div>
      </section>
      <CarouselComponent />
    </div>
  );
}

export default AboutUs;
