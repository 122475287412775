import React from "react";
import CarouselComponent from "../Components/CarouselComponent";
import Header from "../Components/Header";
import { useDocumentTitle } from "../Util/hooks/useDocumentTitle";

function CavityWallExtraction(props) {
  useDocumentTitle("Cavity Wall Extraction");
  return (
    <div>
      <div class="header-bg">
        <div class="navbar-section-we_mv2">
          <Header />
          <section class="banner-section">
            <div class="container ">
              <div class="row">
                <div class="col-lg-7 col-md-12 col-sm-12 mt-5">
                  <h1 class="header-txt" data-aos="fade-up">
                    Funding for cavity wall extraction
                  </h1>
                  <div class="qabout-faq">
                    <div class="row">
                      <div class="col-md-3">
                        <div class="qaboutbtn">
                          <a href="/faqs" class="qabout-btn">
                            FAQs
                          </a>
                        </div>
                      </div>
                      <div class="col-md-9">
                        <h4>
                          Got a question first?
                          <br />
                          Visit out FAQs page
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 mt-3">
                  <div class="qa-faq" data-aos="fade-down">
                    <h2 class="fdaont_2">
                      How efficient is
                      <br />{" "}
                      <span>
                        <span class="green-light">YOUR</span>{" "}
                      </span>
                      home?
                    </h2>
                    <div class="company_btn_wrapper">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        class="text-decoration-none learn_btn_company_stats"
                        href="https://docs.google.com/forms/d/e/1FAIpQLSd4e33mW5w0Uk_ySZ3OKX0XxS_6eh_fuawc6_bVU6xe-JRgtQ/viewform?usp=sf_link"
                      >
                        CHECK ELIGIBILITY
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <section class="step-sec-about mt-3">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="step-box">
                <h2 class="green-dark">THREE SIMPLE STEPS:</h2>
                <div class="row">
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon1.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        Take 60 seconds of your <br />
                        time to fill out the form
                      </h5>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon2.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        We’ll let you know whether <br />
                        you’re eligible for a grant
                      </h5>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon3.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        Our engineers will be in touch to <br />
                        book a survey and installation date
                      </h5>
                    </div>
                  </div>
                </div>
                <span>
                  No more days and nights spent battling the cold. Keep your
                  home nice and cosy and keep those energy bills low.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="about_us"
        style={{ backgroundColor: "#fff", paddingBottom: 0 }}
      >
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <img src="assets/images/cavity_mv2.webp" width="100%" alt="" />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_content">
                <h3 class="fsont__3" data-aos="fade-right">
                  <span>
                    <span>The experts handle cavity wall removal </span>
                    <span class="green-light">in an efficient manner.</span>
                  </span>
                </h3>

                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  In order to make their homes warmer, cavity wall insulation is
                  installed in many homes. While this has many advantages, there
                  is also a chance that the insulation can become wet and weaken
                  the structure of your house.
                </p>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  We at Princeton Eco Plus Ltd. can assist you in obtaining
                  funds for cavity wall removal. Free of charge, our team of
                  trained insulation specialists will inspect your house to
                  determine its state and the best course of action. We
                  cooperate with low-income or vulnerable homes to offer the
                  necessary heating upgrades because we think that everyone
                  deserves to live in a safe and pleasant environment.
                </p>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  Look no further than us if you think your cavity wall may be
                  having issues and need to have it extracted. The positive news
                  is that the cavity wall extraction process is straightforward,
                  so after we've verified your eligibility, we can get to work
                  eliminating the hollow wall's detrimental effects. Get
                  assistance from our knowledgeable experts today to keep your
                  home warm all year long.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="about_us"
        style={{ backgroundColor: "#fff", paddingBottom: 0 }}
      >
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_content">
                <h3 class="fsont__3" data-aos="fade-right">
                  <span>
                    <span>Why should I take out the </span>
                    <span class="green-light">
                      insulation in my cavity walls
                    </span>
                  </span>
                </h3>

                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  There are numerous other factors to consider before deciding
                  whether hollow wall extraction is the best course of action
                  for your home. The following are only a few of the many
                  explanations provided by our clients in the UK:{" "}
                </p>
                <ul class="font_7">
                  <li>
                    <p class="font_7">
                      Having problems with the initial installation
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      Structural problems made worse by the insulation&nbsp;
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      Blockages brought by by construction projects{" "}
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      Old, ineffective insulation that is decaying&nbsp;
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      Inadequate cavity preparation—was the cavity properly
                      cleared?
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      Location: Homes that are exposed to strong winds and rain
                      are not the best candidates for cavity wall
                      insulation&nbsp;
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      Indications of moisture, mould, and wet spots{" "}
                    </p>
                  </li>
                  <li>
                    <p class="font_7">Rising energy prices</p>
                  </li>
                </ul>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  Problems with your cavity wall insulation frequently take time
                  to manifest. Because of this, Princeton Eco Plus Ltd advises
                  taking proactive action by providing finance and other
                  alternatives that maximise the energy efficiency of your home.
                  Please get in touch with our team to talk about your property.
                  To keep you and our professionals safe, we're adhering to the
                  most recent Covid recommendations.
                </p>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <img src="assets/images/caritymv2.webp" width="100%" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section class="red-sec">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h5>Are you eligible for funding?</h5>
            </div>
          </div>
        </div>
      </section>
      <CarouselComponent />
    </div>
  );
}

export default CavityWallExtraction;
