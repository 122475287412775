import React from "react";
import CarouselComponent from "../Components/CarouselComponent";
import Header from "../Components/Header";
import { useDocumentTitle } from "../Util/hooks/useDocumentTitle";

function CavityWall(props) {
  useDocumentTitle("External Wall Insulation");
  return (
    <div>
      <div class="header-bg">
        <div class="navbar-section">
          <Header />
          <section class="banner-section">
            <div class="container">
              <div class="row">
                <div class="col-lg-7 col-md-12 col-sm-12">
                  <h1 class="header-txt" data-aos="fade-up">
                    Grant for free external wall insulation. 100% free to
                    install and fully backed by the UK governmentl
                  </h1>
                  <p class="faont_7">
                    <span class="color_11">
                      In numerous ways, the ECO4 Scheme aids struggling families
                      and homeowners. One of these methods is the free
                      installation of external wall insulation in qualified
                      homes.
                    </span>
                  </p>
                  <p class="faont_7">
                    <span class="color_11">
                      You have a strong possibility of becoming eligible for the
                      grant (and It only takes a minute to find out!)
                    </span>
                  </p>

                  <p class="listheader" data-aos="fade-right">
                    <svg
                      preserveAspectRatio="xMidYMid meet"
                      data-bbox="36 47 128 106"
                      viewBox="36 47 128 106"
                      height="200"
                      width="200"
                      xmlns="http://www.w3.org/2000/svg"
                      data-type="color"
                      role="presentation"
                      aria-hidden="true"
                    >
                      <defs>
                        <style>
                          {`
                                            #comp-l11xk6rv svg [data-color="1"] {
                                                fill: #FFFFFF;
                                            }`}
                        </style>
                      </defs>
                      <g>
                        <path
                          d="M136.701 58.951l15.34 15.323-66.836 66.777-37.247-37.207 15.34-15.328L85.205 110.4l51.496-51.449zm0-11.951L85.204 98.454 63.297 76.569 36 103.846 85.205 153 164 74.274 136.701 47z"
                          fill="#2F54DD"
                          data-color="1"
                        ></path>
                      </g>
                    </svg>
                    &nbsp;
                    <span>
                      Homeowner or private tenant (if renting, you will need
                      landlord’s permission)?
                    </span>
                  </p>
                  <br />

                  <p class="listheader" data-aos="fade-right">
                    <svg
                      preserveAspectRatio="xMidYMid meet"
                      data-bbox="36 47 128 106"
                      viewBox="36 47 128 106"
                      height="200"
                      width="200"
                      xmlns="http://www.w3.org/2000/svg"
                      data-type="color"
                      role="presentation"
                      aria-hidden="true"
                    >
                      <defs>
                        <style>
                          {`
                                            #comp-l11xk6rv svg [data-color="1"] {
                                                fill: #FFFFFF;
                                            }`}
                        </style>
                      </defs>
                      <g>
                        <path
                          d="M136.701 58.951l15.34 15.323-66.836 66.777-37.247-37.207 15.34-15.328L85.205 110.4l51.496-51.449zm0-11.951L85.204 98.454 63.297 76.569 36 103.846 85.205 153 164 74.274 136.701 47z"
                          fill="#2F54DD"
                          data-color="1"
                        ></path>
                      </g>
                    </svg>
                    &nbsp;<span>Receiving income-based benefits?</span>
                  </p>
                  <br />

                  <p class="listheader" data-aos="fade-right">
                    <svg
                      preserveAspectRatio="xMidYMid meet"
                      data-bbox="36 47 128 106"
                      viewBox="36 47 128 106"
                      height="200"
                      width="200"
                      xmlns="http://www.w3.org/2000/svg"
                      data-type="color"
                      role="presentation"
                      aria-hidden="true"
                    >
                      <defs>
                        <style>
                          {`
                                            #comp-l11xk6rv svg [data-color="1"] {
                                                fill: #FFFFFF;
                                            }`}
                        </style>
                      </defs>
                      <g>
                        <path
                          d="M136.701 58.951l15.34 15.323-66.836 66.777-37.247-37.207 15.34-15.328L85.205 110.4l51.496-51.449zm0-11.951L85.204 98.454 63.297 76.569 36 103.846 85.205 153 164 74.274 136.701 47z"
                          fill="#2F54DD"
                          data-color="1"
                        ></path>
                      </g>
                    </svg>
                    &nbsp;<span>Live in a high-carbon emitting home?</span>
                  </p>
                  <br />
                  <p class="listheader" data-aos="fade-right">
                    <svg
                      preserveAspectRatio="xMidYMid meet"
                      data-bbox="36 47 128 106"
                      viewBox="36 47 128 106"
                      height="200"
                      width="200"
                      xmlns="http://www.w3.org/2000/svg"
                      data-type="color"
                      role="presentation"
                      aria-hidden="true"
                    >
                      <defs>
                        <style>
                          {`
                                            #comp-l11xk6rv svg [data-color="1"] {
                                                fill: #FFFFFF;
                                            }`}
                        </style>
                      </defs>
                      <g>
                        <path
                          d="M136.701 58.951l15.34 15.323-66.836 66.777-37.247-37.207 15.34-15.328L85.205 110.4l51.496-51.449zm0-11.951L85.204 98.454 63.297 76.569 36 103.846 85.205 153 164 74.274 136.701 47z"
                          fill="#2F54DD"
                          data-color="1"
                        ></path>
                      </g>
                    </svg>
                    &nbsp;
                    <span>Struggling to cope with the cost of living?</span>
                  </p>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12">
                  <div class="qa-faq" data-aos="fade-down">
                    <h2 class="fdaont_2">
                      Applications for free external wall insulation are
                      limited,{" "}
                      <span>
                        <span class="green-light">
                          so take action and apply now
                        </span>
                      </span>
                    </h2>
                    <div class="company_btn_wrapper">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        class="text-decoration-none learn_btn_company_stats"
                        href="https://docs.google.com/forms/d/e/1FAIpQLSd4e33mW5w0Uk_ySZ3OKX0XxS_6eh_fuawc6_bVU6xe-JRgtQ/viewform?usp=sf_link"
                      >
                        CHECK ELIGIBILITY
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <section>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-8">
              <div class="qa-faq">
                <div class="row">
                  <div class="col-md-9">
                    <h4>Got a question first? Visit our FAQs page</h4>
                  </div>
                  <div class="col-md-3">
                    <div class="farbtn">
                      <a href="/faqs" class="faq-btn">
                        FAQs
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="step-sec">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="step-box">
                <h2>THREE SIMPLE STEPS:</h2>
                <div class="row">
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon1.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        Take 60 seconds of your <br />
                        time to fill out the form
                      </h5>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon2.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        We’ll let you know whether <br />
                        you’re eligible for a grant
                      </h5>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon3.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        Our engineers will be in touch to <br />
                        book a survey and installation date
                      </h5>
                    </div>
                  </div>
                </div>
                <span>
                  No more days and nights spent battling the cold. Keep your
                  home nice and cosy and keep those energy bills low.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="about_us"
        style={{ backgroundColor: "#fff", paddingBottom: 0 }}
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_content">
                <h3 class="fsont__3" data-aos="fade-right">
                  <span>
                    <span>
                      What is external wall insulation, and how will it{" "}
                    </span>
                    <span class="green-light">
                      enable me to make financial savings?
                    </span>
                  </span>
                </h3>

                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  Two layers of brick with a space (or external) between them
                  make up external walls. In modern homes, the external is
                  filled with insulation, however this was not the case in
                  houses constructed between 1924 and 1982.
                </p>
                <p class="font_147">
                  The warmth and comfort levels in your home are maintained at
                  their highest possible level by the external wall insulation,
                  which also serves to keep the cold out and the heat in. Do not
                  be concerned about the fact that it may be made of mineral
                  wool, foam, or beads. To determine which type is ideal for
                  you, one of our skilled and authorised surveyors will inspect
                  around your property.
                </p>
                <h2 class="font_122" data-aos="fade-right">
                  What happens if I decide external wall insulation is not
                  worthwhile?{" "}
                </h2>
                <p class="about_us_paragraph mb-0" data-aos="fade-right">
                  Without external wall insulation, your home's central heating
                  is essentially working extra to produce heat that is merely
                  escaping out of your property. But by enrolling you in the UK
                  government's FREE external wall insulation grant programme,
                  Princeton Eco Plus Ltd. can assist you in putting a stop to
                  this at no cost to you.
                </p>
                <p class="about_us_paragraph mb-0" data-aos="fade-right">
                  Apply right away. It only takes a minute. When we hear from
                  you, we'll handle the rest.
                </p>
                <div class="company_btn_wrapper">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    class="text-decoration-none learn_btn_company_stats"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSd4e33mW5w0Uk_ySZ3OKX0XxS_6eh_fuawc6_bVU6xe-JRgtQ/viewform?usp=sf_link"
                  >
                    CHECK ELIGIBILITY
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_image" data-aos="fade-right">
                <figure class="mb-0">
                  <img src="assets/images/external-wall.jpg" alt="" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="freesec">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-10">
              <div class="free-complete">
                <div class="row">
                  <div class="col-md-5">
                    <div class="fretxt">
                      <h3
                        class="fon12t_3 aos-init aos-animate"
                        data-aos="fade-right"
                      >
                        <span>
                          <span class="color_25">
                            How is that energy companies are doing this{" "}
                          </span>
                          <span class="green-light">for FREE? </span>
                        </span>
                      </h3>
                      <p class="font_147">
                        It’s all part of the Energy Company Obligation run by
                        the British government.{" "}
                        <span style={{ textDecoration: "underline" }}>
                          <a
                            href="https://www.ofgem.gov.uk/environmental-and-social-schemes/energy-company-obligation-eco"
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            Find out more about ECO here
                          </a>
                        </span>
                        .
                      </p>
                      <p class="font_147">
                        <span class="wixGuard">&ZeroWidthSpace;</span>The
                        benefits of FREE external wall insulation courtesy of
                        Princeton Eco Plus Ltd., and the government’s ECO4
                        scheme:
                      </p>
                      <div class="bor-bot aos-init" data-aos="fade-left"></div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <div class="freebox elec-hea">
                      <p
                        class="listheader aos-init aos-animate"
                        data-aos="fade-right"
                      >
                        <svg
                          preserveAspectRatio="xMidYMid meet"
                          data-bbox="36 47 128 106"
                          viewBox="36 47 128 106"
                          height="200"
                          width="200"
                          xmlns="http://www.w3.org/2000/svg"
                          data-type="color"
                          role="presentation"
                          aria-hidden="true"
                        >
                          <defs>
                            <style>
                              {`#comp-l11xk6rv svg [data-color="1"] {
                                                        fill: #99CA3C;
                                                    }`}
                            </style>
                          </defs>
                          <g>
                            <path
                              d="M136.701 58.951l15.34 15.323-66.836 66.777-37.247-37.207 15.34-15.328L85.205 110.4l51.496-51.449zm0-11.951L85.204 98.454 63.297 76.569 36 103.846 85.205 153 164 74.274 136.701 47z"
                              fill="#2F54DD"
                              data-color="1"
                            ></path>
                          </g>
                        </svg>
                        &nbsp;
                        <span>
                          <b>FREE AND GOVERNMENT FUNDED</b> Due to the
                          government’s ECO4 scheme, you won’t have to pay for
                          installation.
                        </span>
                      </p>
                      <p
                        class="listheader aos-init aos-animate"
                        data-aos="fade-right"
                      >
                        <svg
                          preserveAspectRatio="xMidYMid meet"
                          data-bbox="36 47 128 106"
                          viewBox="36 47 128 106"
                          height="200"
                          width="200"
                          xmlns="http://www.w3.org/2000/svg"
                          data-type="color"
                          role="presentation"
                          aria-hidden="true"
                        >
                          <defs>
                            <style>
                              {`#comp-l11xk6rv svg [data-color="1"] {
                                                        fill: #99CA3C;
                                                    }`}
                            </style>
                          </defs>
                          <g>
                            <path
                              d="M136.701 58.951l15.34 15.323-66.836 66.777-37.247-37.207 15.34-15.328L85.205 110.4l51.496-51.449zm0-11.951L85.204 98.454 63.297 76.569 36 103.846 85.205 153 164 74.274 136.701 47z"
                              fill="#2F54DD"
                              data-color="1"
                            ></path>
                          </g>
                        </svg>
                        &nbsp;
                        <span>
                          <b>SAVE MONEY</b> Did you know properties lose A THIRD
                          of their heat via their walls? External wall
                          insulation puts a stop to this.
                        </span>
                      </p>
                      <p
                        class="listheader aos-init aos-animate"
                        data-aos="fade-right"
                      >
                        <svg
                          preserveAspectRatio="xMidYMid meet"
                          data-bbox="36 47 128 106"
                          viewBox="36 47 128 106"
                          height="200"
                          width="200"
                          xmlns="http://www.w3.org/2000/svg"
                          data-type="color"
                          role="presentation"
                          aria-hidden="true"
                        >
                          <defs>
                            <style>
                              {`#comp-l11xk6rv svg [data-color="1"] {
                                                        fill: #99CA3C;
                                                    }`}
                            </style>
                          </defs>
                          <g>
                            <path
                              d="M136.701 58.951l15.34 15.323-66.836 66.777-37.247-37.207 15.34-15.328L85.205 110.4l51.496-51.449zm0-11.951L85.204 98.454 63.297 76.569 36 103.846 85.205 153 164 74.274 136.701 47z"
                              fill="#2F54DD"
                              data-color="1"
                            ></path>
                          </g>
                        </svg>
                        &nbsp;
                        <span>
                          <b>A WARMER, COSIER HOME</b> Less heat lost means
                          extra warmth and comfort in your property.
                        </span>
                      </p>
                      <p class="listheader aos-init" data-aos="fade-right">
                        <svg
                          preserveAspectRatio="xMidYMid meet"
                          data-bbox="36 47 128 106"
                          viewBox="36 47 128 106"
                          height="200"
                          width="200"
                          xmlns="http://www.w3.org/2000/svg"
                          data-type="color"
                          role="presentation"
                          aria-hidden="true"
                        >
                          <defs>
                            <style>
                              {`#comp-l11xk6rv svg [data-color="1"] {
                                                        fill: #99CA3C;
                                                    }`}
                            </style>
                          </defs>
                          <g>
                            <path
                              d="M136.701 58.951l15.34 15.323-66.836 66.777-37.247-37.207 15.34-15.328L85.205 110.4l51.496-51.449zm0-11.951L85.204 98.454 63.297 76.569 36 103.846 85.205 153 164 74.274 136.701 47z"
                              fill="#2F54DD"
                              data-color="1"
                            ></path>
                          </g>
                        </svg>
                        &nbsp;
                        <span>
                          <b>BOOSTS PROPERTY VALUE</b> Sell your property at a
                          higher price, should you choose to move house
                        </span>
                      </p>
                      <p class="listheader aos-init" data-aos="fade-right">
                        <svg
                          preserveAspectRatio="xMidYMid meet"
                          data-bbox="36 47 128 106"
                          viewBox="36 47 128 106"
                          height="200"
                          width="200"
                          xmlns="http://www.w3.org/2000/svg"
                          data-type="color"
                          role="presentation"
                          aria-hidden="true"
                        >
                          <defs>
                            <style>
                              {`#comp-l11xk6rv svg [data-color="1"] {
                                                        fill: #99CA3C;
                                                    }`}
                            </style>
                          </defs>
                          <g>
                            <path
                              d="M136.701 58.951l15.34 15.323-66.836 66.777-37.247-37.207 15.34-15.328L85.205 110.4l51.496-51.449zm0-11.951L85.204 98.454 63.297 76.569 36 103.846 85.205 153 164 74.274 136.701 47z"
                              fill="#2F54DD"
                              data-color="1"
                            ></path>
                          </g>
                        </svg>
                        &nbsp;
                        <span>
                          <b>MAINTENANCE FREE</b> When installed by the expert,
                          fully qualified and friendly team here at Princeton
                          Eco Plus Ltd.
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="about_us"
        style={{ backgroundColor: "#fff", padding: "50px 0" }}
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_content">
                <h3
                  class="font_3"
                  style={{
                    fontSize: "30px",
                    lineHeight: "28px",
                    color: "#0c3e35",
                  }}
                  data-aos="fade-right"
                >
                  <span
                    style={{
                      fontSize: "30px",
                    }}
                  >
                    <span
                      style={{
                        color: "#0c3e35",
                      }}
                    >
                      Is my house{" "}
                    </span>
                    <span class="green-light">appropriate?</span>
                  </span>
                </h3>
                <p class="about_us_paragraph mb-0" data-aos="fade-right">
                  You don't have to be aware of this beforehand! One of our
                  surveyors will visit your property to conduct an inspection
                  and keep you updated as soon as you apply. Listed below are a
                  few signs that you qualify for a free external wall insulation
                  grant.
                </p>
                <ul class="font_7">
                  <li>
                    <p class="font_7">
                      External walls make up the exterior walls.
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      The absence of external wall insulation at the moment
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      Having a 5 cm broad external (or more){" "}
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      Before 1982 (but properties built before 2005 may also
                      qualify)
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      Exterior walls, masonry, and brickwork in fair shape.{" "}
                    </p>
                  </li>
                  <li>
                    <p class="font_7">Home is not at risk of floods.</p>
                  </li>
                  <li>
                    <p class="font_7">
                      Walls that aren't unduly exposed to windy conditions and
                      bad weather{" "}
                    </p>
                  </li>
                </ul>
                <div class="company_btn_wrapper">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    class="text-decoration-none learn_btn_company_stats"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSd4e33mW5w0Uk_ySZ3OKX0XxS_6eh_fuawc6_bVU6xe-JRgtQ/viewform?usp=sf_link"
                  >
                    CHECK ELIGIBILITY
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_image" data-aos="fade-right">
                <figure class="mb-0">
                  <img src="assets/images/external-wall-2.jpg" alt="" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section style={{ padding: "50px 0" }}>
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <iframe
                width="100%"
                height="309"
                src="https://www.youtube.com/embed/xk6JAJ5f5wA"
                title="External Wall Insulation"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div class="col-md-6">
              <iframe
                width="100%"
                height="309"
                src="https://www.youtube.com/embed/ViRMIF7jJ4A"
                title="External Wall Insulation"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </section>

      <section class="red-sec">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h5>
                The only way to know you qualify for free external wall
                insulation is to apply. Get things started by getting in touch
                with the helpful Princeton Eco Plus Ltd. energy staff right now.
              </h5>
            </div>
          </div>
        </div>
      </section>
      <CarouselComponent />
      {/* <section class="our_testimonials center-style float-left w-100">
        <div class="titleprof">
          <div class="container">
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12">
                <h2>Professional Accreditations</h2>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-2">
              <div class="test-left">
                <img src="assets/images/green.webp" alt="" />
                <p>Reg. No NAPI51657</p>
                <p class="texta">
                  *external wall insulation including that installed in party
                  walls, floor insulation, loft insulation (roll insulation &
                  blown insulation), room in roof insulation, condensing
                  boilers, natural gas fired and liquefied petroleum gas fired,
                  heating controls, electric storage heaters
                </p>
              </div>
            </div>
            <div class="col-md-10">
              <div class="row position-relative">
                <div class="carousel-content" data-aos="fade-up">
                  <div class="carousel-outer">
                    <div class="owl-carousel owl-theme">
                      <div class="item">
                        <div class="testimonials_content">
                          <img src="assets/images/logos/1.webp" alt="" />
                        </div>
                      </div>
                      <div class="item">
                        <div class="testimonials_content">
                          <img src="assets/images/logos/2.webp" alt="" />
                        </div>
                      </div>
                      <div class="item">
                        <div class="testimonials_content">
                          <img src="assets/images/logos/3.webp" alt="" />
                        </div>
                      </div>
                      <div class="item">
                        <div class="testimonials_content">
                          <img src="assets/images/logos/4.webp" alt="" />
                        </div>
                      </div>
                      <div class="item">
                        <div class="testimonials_content">
                          <img src="assets/images/logos/5.webp" alt="" />
                        </div>
                      </div>
                      <div class="item">
                        <div class="testimonials_content">
                          <img src="assets/images/logos/6.webp" alt="" />
                        </div>
                      </div>
                      <div class="item">
                        <div class="testimonials_content">
                          <img src="assets/images/logos/7.webp" alt="" />
                        </div>
                      </div>
                      <div class="item">
                        <div class="testimonials_content">
                          <img src="assets/images/logos/8.webp" alt="" />
                        </div>
                      </div>
                      <div class="item">
                        <div class="testimonials_content">
                          <img src="assets/images/logos/9.webp" alt="" />
                        </div>
                      </div>
                      <div class="item">
                        <div class="testimonials_content">
                          <img src="assets/images/logos/10.webp" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
}

export default CavityWall;
