import React from "react";
import CarouselComponent from "../Components/CarouselComponent";
import Header from "../Components/Header";

function SmartControls(props) {
  return (
    <div>
      <div class="header-bg">
        <div class="navbar-section-inner">
          <Header />
          <section class="banner-section">
            <div class="container">
              <div class="row">
                <div class="col-lg-7 col-md-12 col-sm-12">
                  <h1 class="header-txt" data-aos="fade-up">
                    <img
                      src="assets/images/wiserlogo.webp"
                      style={{
                        backgroundColor: "#fff",
                        padding: "8px",
                        borderRadius: "12px",
                      }}
                      alt=""
                    />
                    <span class="h1-text">Wiser smart controls by Drayton</span>
                  </h1>
                  <br />
                  <p class="fontww_7">
                    <span class="color_11">
                      We’re really excited to be able to offer you the Wiser
                      smart heating control system (if your home qualifies).
                      &nbsp;This comprehensive multi-zone system from Drayton,
                      allows independent temperature control for each room
                      throughout your home and could save{" "}
                      <span style={{ fontWeight: "bold" }}>
                        up to £450 on your energy bills.
                      </span>
                    </span>
                  </p>
                  <br />
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12">
                  <div class="qa-faq" data-aos="fade-down">
                    <h2 class="fwont_2 " style={{ color: "#324158" }}>
                      Applications for free cavity wall insulation are limited,{" "}
                      <span>
                        <span class="green-light">
                          so take action and apply now
                        </span>
                      </span>
                    </h2>
                    <div class="company_btn_wrapper">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        class="text-decoration-none learn_btn_company_stats"
                        href="https://docs.google.com/forms/d/e/1FAIpQLSd4e33mW5w0Uk_ySZ3OKX0XxS_6eh_fuawc6_bVU6xe-JRgtQ/viewform?usp=sf_link"
                      >
                        CHECK ELIGIBILITY
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <section>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-8">
              <div class="qa-faq">
                <div class="row">
                  <div class="col-md-9">
                    <h4>Got a question first? Visit our FAQs page</h4>
                  </div>
                  <div class="col-md-3">
                    <div class="farbtn">
                      <a href="/faqs" class="faq-btn">
                        FAQs
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="step-sec">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="step-box">
                <h2>THREE SIMPLE STEPS:</h2>
                <div class="row">
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon1.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        Take 60 seconds of your <br />
                        time to fill out the form
                      </h5>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon2.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        We’ll let you know whether <br />
                        you’re eligible for a grant
                      </h5>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon3.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        Our engineers will be in touch to <br />
                        book a survey and installation date
                      </h5>
                    </div>
                  </div>
                </div>
                <span>
                  No more days and nights spent battling the cold. Keep your
                  home nice and cosy and keep those energy bills low.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="about_us"
        style={{ backgroundColor: "#fff", paddingBottom: 0 }}
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_content">
                <img src="assets/images/wiserlogo.webp" alt="" />
                <div class="saveuptxt">
                  Save up to £450 on <br />
                  your energy bills
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="row">
                <div class="col-md-4 text-center">
                  <img src="assets/images/eco-icon1.webp" alt="" />
                  <p class="green-light mb-0" style={{ color: "#4fce5e" }}>
                    <strong>Smart modes</strong>
                  </p>
                  <p>16% saving</p>
                </div>
                <div class="col-md-4 text-center">
                  <img src="assets/images/eco-icon2.webp" alt="" />
                  <p class="green-light mb-0" style={{ color: "#4fce5e" }}>
                    <strong>Individual room control</strong>
                  </p>
                  <p>19% saving</p>
                </div>
                <div class="col-md-4 text-center">
                  <img src="assets/images/eco-icon3.webp" alt="" />
                  <p class="green-light mb-0" style={{ color: "#4fce5e" }}>
                    <strong>Smart thermostat</strong>
                  </p>
                  <p>18% saving</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="about_us"
        style={{ backgroundColor: "#fff", padding: "50px 0" }}
      >
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 text-center">
              <div class="aboutus_image" data-aos="fade-right">
                <figure class="mb-0">
                  <img
                    src="assets/images/diagram.webp"
                    style={{ width: "auto !important" }}
                    alt=""
                  />
                </figure>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_content">
                <h3 class="fossnt_3">
                  <span>
                    <span>Complete home heating&nbsp;</span>
                    <span class="green-light">with Wiser</span>
                  </span>
                </h3>
                <ul class="font_7">
                  <li>
                    <p class="font_7">
                      Control your heating from anywhere in the world with the
                      Wiser Home app
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      The Wiser Room Thermostat provides central smart control
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      Set individual temperatures in every room with the Wiser
                      Radiator Thermostat
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      Control your wet underfloor heating via the same Wiser
                      Home app
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      Schedule your electrical heating devices: electric
                      radiators, towel rails, electrical UFH
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      Wiser integrates with your smart home devices including
                      voice assistants and IFTTT
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        class="about_us"
        style={{ backgroundColor: "#fff", padding: "50px 0" }}
      >
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_content">
                <h3 class="fossnt_3">
                  Wiser{" "}
                  <span>
                    <span class="green-light">Room Thermostat</span>
                  </span>
                </h3>
                <ul class="font_7">
                  <li>
                    <p class="font_7">
                      Completely wireless, can be mounted on table stand or wall
                      clip
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      Central temperature control via the Wiser Home app
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      Energy saving modes to help with reducing energy bills
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      Works with Amazon Alexa, the Google Assistant and IFTTT
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      Capacitive touch buttons to boost and adjust the room
                      temperature
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_image" data-aos="fade-right">
                <figure class="mb-0">
                  <img src="assets/images/thermostat.webp" alt="" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        class="about_us"
        style={{ backgroundColor: "#fff", padding: "50px 0" }}
      >
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_image" data-aos="fade-right">
                <figure class="mb-0">
                  <img src="assets/images/thermostata.webp" alt="" />
                </figure>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_content">
                <h3 class="fossnt_3">
                  Wiser{" "}
                  <span>
                    <span class="green-light">Radiator Thermostat</span>
                  </span>
                </h3>
                <ul class="font_7">
                  <li>
                    <p class="font_7">Independent room temperature control</p>
                  </li>
                  <li>
                    <p class="font_7">
                      Easy to install, fits on most existing radiator valves
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      Award winning twist-top boost with spring back
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      Integrates with your smart home devices
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        class="about_us"
        style={{ backgroundColor: "#fff", padding: "50px 0" }}
      >
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_content">
                <h3 class="fossnt_3">
                  <span>
                    Wiser <span class="green-light">Home app</span>
                  </span>
                </h3>
                <p class="font_7">
                  The Wiser Home app enables you to have better control over
                  your entire Wiser heating system from one easy to use app,
                  from anywhere in the world.
                </p>
                <p class="font_7">
                  <br />
                  Boost your room temperature with a simple tap, set or amend
                  your heating schedules, check your energy consumption via
                  Insights and Heat Report.
                </p>
                <p class="font_7">
                  &nbsp;
                  <br />
                  These features and Wiser smart modes are all designed to make
                  it easy for you to save money on your energy bills.
                </p>

                <p style={{ fontSize: "26px", color: "#0c3e35" }}>
                  <strong>Download in the App Store – search Wiser Home</strong>
                </p>

                <div>
                  <img src="assets/images/googleplaystore.webp" alt="" />
                  <img src="assets/images/appstore.webp" alt="" />
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_image" data-aos="fade-right">
                <figure class="mb-0">
                  <img src="assets/images/happ.webp" alt="" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section style={{ backgroundColor: "#fff", padding: "50px 0" }}>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-8">
              <iframe
                width="100%"
                height="489"
                src="https://www.youtube.com/embed/J_s5RVpqG7w"
                title="Wiser - Smart Heating Controls That Take Care Of Your Home And The Planet | Wiser by Drayton"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </section>

      <section class="red-sec">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h5>
                Over 70 years of engineering expertise and manufacturing
                proficiency has culminated in the creation of Wiser, making
                smart and sustainable homes simple and affordable.
              </h5>
            </div>
          </div>
        </div>
      </section>
      <CarouselComponent />
    </div>
  );
}

export default SmartControls;
