import React from "react";
import CarouselComponent from "../Components/CarouselComponent";
import Header from "../Components/Header";
import { useDocumentTitle } from "../Util/hooks/useDocumentTitle";

function SolarEnergy(props) {
  useDocumentTitle("Solar Energry");
  return (
    <div>
      <div class="header-bg">
        <div class="navbar-section-solar_mv2">
          <Header />
          <section class="banner-section">
            <div class="container">
              <div class="row">
                <div class="col-lg-7 col-md-12 col-sm-12">
                  <h1 class="header-txt" data-aos="fade-up">
                    Do I Qualify for a Government Grant for Solar Energy?
                  </h1>
                  <div class="qabout-faq">
                    <div class="row">
                      <div class="col-md-3">
                        <div class="qaboutbtn">
                          <a href="/faqs" class="qabout-btn">
                            FAQs
                          </a>
                        </div>
                      </div>
                      <div class="col-md-9">
                        <h4>
                          Got a question first?
                          <br />
                          Visit out FAQs page
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 mt-3">
                  <div class="qa-faq" data-aos="fade-down">
                    <h2 class="fdaont_2">
                      How efficient is
                      <br />{" "}
                      <span>
                        <span class="green-light">YOUR</span>
                      </span>{" "}
                      home?
                    </h2>
                    <div class="company_btn_wrapper">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        class="text-decoration-none learn_btn_company_stats"
                        href="https://docs.google.com/forms/d/e/1FAIpQLSd4e33mW5w0Uk_ySZ3OKX0XxS_6eh_fuawc6_bVU6xe-JRgtQ/viewform?usp=sf_link"
                      >
                        CHECK ELIGIBILITY
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <section class="step-sec-about mt-3">
        <div class="container ">
          <div class="row">
            <div class="col-md-12">
              <div class="step-box">
                <h2 class="green-light">THREE SIMPLE STEPS:</h2>
                <div class="row">
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon1.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        Take 60 seconds of your <br />
                        time to fill out the form
                      </h5>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon2.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        We’ll let you know whether <br />
                        you’re eligible for a grant
                      </h5>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon3.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        Our engineers will be in touch to <br />
                        book a survey and installation date
                      </h5>
                    </div>
                  </div>
                </div>
                <span>
                  No more days and nights spent battling the cold. Keep your
                  home nice and cosy and keep those energy bills low.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="about_us"
        style={{ backgroundColor: "#fff", paddingBottom: 0 }}
      >
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <img src="assets/images/solarms.webp" width="100%" alt="" />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_content">
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  The fact that energy prices are rising is an unhappy reality.
                  The more energy we can obtain from renewable sources, the less
                  dependent we are on the ever rising market prices for
                  electricity and gas.
                </p>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  Solar panels are an extra measure that will be part of the ECO
                  4 programme beginning in April 2022. In addition to other
                  steps, solar panels can be available to help your home's
                  energy efficiency rating.
                </p>
                <h3 class="fsont__3" data-aos="fade-right">
                  <span>
                    <span>What are the </span>
                    <span class="green-light">benefits of Solar?</span>
                  </span>
                </h3>

                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  Installing solar panels has a lot of advantages, including the
                  following:
                </p>
                <ul class="font_7">
                  <li>
                    <p class="font_7">
                      Aids in defending your home from rising energy prices{" "}
                    </p>
                  </li>
                  <li>
                    <p class="font_7">Reduce your energy costs</p>
                  </li>
                  <li>
                    <p class="font_7">
                      Decreases carbon footprint, which benefits the environment{" "}
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      Energy is produced, even on cloudy days.
                    </p>
                  </li>
                </ul>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  When you submit an inquiry form, we will automatically
                  determine whether you qualify for this new measure.
                </p>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  Simply fill out this free, no-obligation contact form, and one
                  of our consultants will get in touch with you to go over the
                  options available for your property and circumstances.
                </p>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  Are you paying more for heating your home than is necessary?
                  By clicking here and providing your property information, you
                  may determine how efficient your home is now.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="red-sec">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h5>Fill in the form to check your eligibility.</h5>
            </div>
          </div>
        </div>
      </section>
      <CarouselComponent />
    </div>
  );
}

export default SolarEnergy;
