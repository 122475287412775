import React from "react";
import CarouselComponent from "../Components/CarouselComponent";
import Header from "../Components/Header";
import { useDocumentTitle } from "../Util/hooks/useDocumentTitle";

function Faq(props) {
  useDocumentTitle("Faqs");
  return (
    <div>
      <div class="header-bg">
        <div class="navbar-section-faqinner">
          <Header />
          <section class="banner-section">
            <div class="container">
              <div class="row">
                <div class="col-lg-7 col-md-12 col-sm-12">
                  <h1 class="header-txt" data-aos="fade-up">
                    Frequently Asked <br />
                    Questions
                  </h1>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12">
                  <div class="qa-faq" data-aos="fade-down">
                    <h2 class="fdaont_2">
                      How efficient <br />
                      is{" "}
                      <span>
                        <span class="green-light">YOUR</span>
                      </span>{" "}
                      home?
                    </h2>
                    <div class="company_btn_wrapper">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        class="text-decoration-none learn_btn_company_stats"
                        href="https://docs.google.com/forms/d/e/1FAIpQLSd4e33mW5w0Uk_ySZ3OKX0XxS_6eh_fuawc6_bVU6xe-JRgtQ/viewform?usp=sf_link"
                      >
                        CHECK ELIGIBILITY
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <section class="step-sec-about mt-3">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="step-box">
                <h2 class="green-dark">THREE SIMPLE STEPS:</h2>
                <div class="row">
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon1.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        Take 60 seconds of your <br />
                        time to fill out the form
                      </h5>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon2.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        We’ll let you know whether <br />
                        you’re eligible for a grant
                      </h5>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon3.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        Our engineers will be in touch to <br />
                        book a survey and installation date
                      </h5>
                    </div>
                  </div>
                </div>
                <span>
                  No more days and nights spent battling the cold. Keep your
                  home nice and cosy and keep those energy bills low.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="about_us"
        style={{ backgroundColor: "#fff", paddingBottom: 0 }}
      >
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <img src="assets/images/family.jpg" width="100%" alt="" />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_content">
                <h3 class="fsont__3" data-aos="fade-right">
                  <span>
                    <span>Who we are </span>
                    <span class="green-light">and how can we help?</span>
                  </span>
                </h3>

                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  Maybe you have a few inquiries regarding who we are and what
                  we do. Let's clear up a couple of those misunderstandings if
                  that applies to you! In order to help you decide whether to
                  hire us, Princeton Eco Plus Ltd is available to answer any
                  questions you may have regarding our services.
                </p>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  Here are some often asked questions in light of that;
                  nevertheless, if you need a bit more information before
                  filling out a form, just give us a call right away. It's also
                  important to note that submitting a form is cost-free and
                  comes with no commitment whatsoever. You are not required to
                  continue even if we later determine that you meet the
                  requirements.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="accordian-section">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="faqhead">
                <h5 class="text-center green-dark">
                  Frequently asked questions
                </h5>
                <h3 class="green-light">FAQs</h3>
              </div>
            </div>
            <div class="col-lg-12 mt-2" data-aos="fade-right">
              <div class="about-service-section-text accordian-section-inner-text position-relative">
                <div class="accordian-inner">
                  <div id="accordion1">
                    <div class="accordion-card">
                      <div class="card-header" id="headingFour">
                        <button
                          class="btn btn-link collapsed"
                          data-toggle="collapse"
                          data-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          Is this legit? How can it be free?
                        </button>
                      </div>
                      <div
                        id="collapseFour"
                        class="collapse"
                        aria-labelledby="headingFour"
                      >
                        <div class="card-body">
                          <p class="text-left mb-0 p-0">
                            It is free because of a government energy efficiency
                            scheme, designed to tackle fuel poverty and reduce
                            carbon emissions across Great Britain. Under the
                            ECO3 and ECO4 schemes, medium and large energy
                            suppliers have targets to meet (ECO stands for
                            “Energy Company Obligation”) and they fund the
                            installation of energy efficient systems in British
                            households.
                            <br />
                            {/* Find out more here:
                            www.ukem.co.uk/post/eco3-scheme-it-s-not-a-scam */}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-card">
                      <div class="card-header" id="headingFive">
                        <button
                          class="btn btn-link collapsed"
                          data-toggle="collapse"
                          data-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          Who are Princeton Eco Plus Ltd?
                        </button>
                      </div>
                      <div
                        id="collapseFive"
                        class="collapse"
                        aria-labelledby="headingFive"
                      >
                        <div class="card-body">
                          <p class="text-left mb-0 p-0">
                            We work with energy companies in Great Britain to
                            find people like you who are eligible for energy
                            efficient upgrades. We take your details and check
                            your eligibility to this government backed scheme.
                            There is no cost to you for using our services and
                            it’s free to find out if you qualify or not.
                            <br />
                            Once you complete the eligibility form, a member of
                            our team will contact you to discuss all the options
                            available to you. You can then decide which measures
                            you want installing; there is no obligation.
                            <br />
                            Once you’re approved, we work around you to install
                            the measures you have chosen.
                            <br />
                            {/* Find out more here:
                            www.ukem.co.uk/post/uk-energy-management-ne-ltd-how-could-we-help-you */}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-card">
                      <div class="card-header" id="headingSix">
                        <button
                          class="btn btn-link collapsed"
                          data-toggle="collapse"
                          data-target="#collapseSix"
                          aria-expanded="false"
                          aria-controls="collapseSix"
                        >
                          Do I have a qualifying household?
                        </button>
                      </div>
                      <div
                        id="collapseSix"
                        class="collapse"
                        aria-labelledby="headingSix"
                      >
                        <div class="card-body">
                          <p class="text-left mb-0 p-0">
                            To qualify for an electric storage heater grant you
                            must be living in private accomodation that is for
                            domestic use.
                            <br />
                            These grants are not available if you live in
                            Council or Housing Association accommodation.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-card">
                      <div class="card-header" id="headingTen">
                        <button
                          class="btn btn-link collapsed"
                          data-toggle="collapse"
                          data-target="#collapseTen"
                          aria-expanded="false"
                          aria-controls="collapseTen"
                        >
                          What type of heating qualifies?
                        </button>
                      </div>
                      <div
                        id="collapseTen"
                        class="collapse"
                        aria-labelledby="headingTen"
                      >
                        <div class="card-body">
                          <p class="text-left mb-0 p-0">
                            If your electric storage heaters are 10+ years old
                            or perhaps one (or several) are faulty, then you
                            should apply for an electric storage heater grant.
                            We can organise high energy efficiency units to be
                            installed by fully accredited partners.
                            <br />
                            If you use standard electric heaters or fan heaters
                            to heat your property then you should apply for this
                            free grant as we can replace your current heaters
                            with new storage heaters.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-card">
                      <div class="card-header" id="headingSeven">
                        <button
                          class="btn btn-link collapsed"
                          data-toggle="collapse"
                          data-target="#collapseSeven"
                          aria-expanded="false"
                          aria-controls="collapseSeven"
                        >
                          What benefits qualify for a grant?
                        </button>
                      </div>
                      <div
                        id="collapseSeven"
                        class="collapse"
                        aria-labelledby="headingSeven"
                      >
                        <div class="card-body">
                          <p class="text-left mb-0 p-0">
                            To qualify for funding, someone living in the
                            property needs to be in receipt of at least one of
                            the benefits listed below:
                            <ul>
                              <li>Armed Forces Independence Payment</li>
                              <li>Attendance Allowance</li>
                              <li>Carer’s Allowance</li>
                              <li>Child Benefit*</li>
                              <li>Child Tax Credit</li>
                              <li>Constant Attendance Allowance</li>
                              <li>Disability Living Allowance</li>
                              <li>Income-Based Jobseekers Allowance</li>
                              <li>
                                Income-Related Employment Support Allowance
                              </li>
                              <li>Income Support</li>
                              <li>Industrial Injuries Disablement Benefit</li>
                              <li>Pension Guarantee Credit</li>
                              <li>Personal Independence Payment (PIP)</li>
                              <li>Severe Disablement Allowance</li>
                              <li>Universal Credit</li>
                              <li>War Pensions Mobility Supplement</li>
                              <li>Working Tax Credit</li>
                              <li>Household income limits apply</li>
                            </ul>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-card">
                      <div class="card-header" id="headingEight">
                        <button
                          class="btn btn-link collapsed"
                          data-toggle="collapse"
                          data-target="#collapseEight"
                          aria-expanded="false"
                          aria-controls="collapseEight"
                        >
                          Why do you need my personal information?
                        </button>
                      </div>
                      <div
                        id="collapseEight"
                        class="collapse"
                        aria-labelledby="headingEight"
                      >
                        <div class="card-body">
                          <p class="text-left mb-0 p-0">
                            We need to validate your eligibility to the scheme
                            by contacting the DWP (Department for Work &
                            Pensions) or speak to your local council in the
                            event you don’t automatically qualify (see flexible
                            eligibility).
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-card">
                      <div class="card-header" id="headingNine">
                        <button
                          class="btn btn-link collapsed"
                          data-toggle="collapse"
                          data-target="#collapseNine"
                          aria-expanded="false"
                          aria-controls="collapseNine"
                        >
                          Do I get a warranty for work carried out?
                        </button>
                      </div>
                      <div
                        id="collapseNine"
                        class="collapse"
                        aria-labelledby="headingNine"
                      >
                        <div class="card-body">
                          <p class="text-left mb-0 p-0">
                            Yes. All of the work we carry out comes with either
                            a warranty or guarantee. Find out more here.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-card">
                      <div class="card-header" id="headingEleven">
                        <button
                          class="btn btn-link collapsed"
                          data-toggle="collapse"
                          data-target="#collapseEleven"
                          aria-expanded="false"
                          aria-controls="collapseEleven"
                        >
                          What areas do you cover?
                        </button>
                      </div>
                      <div
                        id="collapseEleven"
                        class="collapse"
                        aria-labelledby="headingEleven"
                      >
                        <div class="card-body">
                          <p class="text-left mb-0 p-0">
                            We have a national installation team, multiple
                            branches and cover the whole of Great Britain.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="accordion-card">
                      <div class="card-header" id="headingTwelve">
                        <button
                          class="btn btn-link collapsed"
                          data-toggle="collapse"
                          data-target="#collapseTwelve"
                          aria-expanded="false"
                          aria-controls="collapseTwelve"
                        >
                          What are heating grants?
                        </button>
                      </div>
                      <div
                        id="collapseTwelve"
                        class="collapse"
                        aria-labelledby="headingTwelve"
                      >
                        <div class="card-body">
                          <p class="text-left mb-0 p-0">
                            Grants mean you do not have to pay for the heating
                            or energy efficiency measures installed. There is
                            nothing to pay either up front or subsequently
                            because it is done by way of a grant, not a loan.
                            <br />
                            These are given as part of the government energy
                            efficiency scheme, designed to tackle fuel poverty
                            and reduce carbon emissions across Great Britain.
                            Under the ECO3 and ECO4 schemes, medium and large
                            energy suppliers have targets to meet (ECO stands
                            for “Energy Company Obligation”) and they fund the
                            installation of energy efficient systems in British
                            households.
                            <br />
                            {/* Find out more here:
                            www.ukem.co.uk/post/how-can-the-energy-company-obligation-eco-work-for-you */}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="red-sec">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h5>Are you eligible for funding?</h5>
            </div>
          </div>
        </div>
      </section>
      <CarouselComponent />
    </div>
  );
}

export default Faq;
