import React from "react";
import CarouselComponent from "../Components/CarouselComponent";
import Header from "../Components/Header";
import { useDocumentTitle } from "../Util/hooks/useDocumentTitle";

function ContactUs(props) {
  useDocumentTitle("Contact Us");
  return (
    <div>
      <div class="header-bg">
        <div class="navbar-section-contactbg">
          <Header />
          <section class="banner-section">
            <div class="container">
              <div class="row">
                <div class="col-lg-7 col-md-12 col-sm-12">
                  <h1 class="header-txt" data-aos="fade-up">
                    Contact Us
                  </h1>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <section style={{ padding: "30px 0", backgroundColor: "#99ca3c" }}>
        <div class="container">
          <div class="row">
            <div class="col-md-6 text-center">
              <h3 class="font_2">
                <span class="green-dark">Call us on:</span>
                <span>
                  {" "}
                  <a style={{ color: "#FFFFFF" }} href="tel:01384985974">
                    0330 113 0362
                  </a>
                </span>
              </h3>
            </div>
            <div class="col-md-6 text-center">
              <h3 class="font_2">
                <span class="green-dark">Email us:</span>
                <span>
                  {" "}
                  <a
                    style={{ color: "#FFFFFF" }}
                    href="mailto:info@ecoplusprinceton.co.uk"
                  >
                    info@ecoplusprinceton.co.uk
                  </a>
                </span>
              </h3>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-5 justify-content-center mt-3">
              <div class="qa-faq">
                <h2 class="fdaont_2">
                  How efficient is
                  <br />{" "}
                  <span>
                    <span class="green-light">YOUR</span>{" "}
                  </span>
                  home?
                </h2>
                <div class="company_btn_wrapper">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    class="text-decoration-none learn_btn_company_stats"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSd4e33mW5w0Uk_ySZ3OKX0XxS_6eh_fuawc6_bVU6xe-JRgtQ/viewform?usp=sf_link"
                  >
                    CHECK ELIGIBILITY
                  </a>
                </div>
              </div>

              <div class="qabout-faq  mt-3 mb-3">
                <div class="row">
                  <div class="col-md-3">
                    <div class="qaboutbtn">
                      <a href="/faqs" class="qabout-btn">
                        FAQs
                      </a>
                    </div>
                  </div>
                  <div class="col-md-9">
                    <h4>
                      Got a question first?
                      <br />
                      Visit out FAQs page
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section style={{ padding: "30px 0", backgroundColor: "#99ca3c" }}>
        <div class="">
          <div class="row">
            <div class="col-md-12 px-2 text-center">
              <h3 style={{ fontSize: "16px" }} class="font_2">
                <span class="green-dark">
                  3 Brook Holloway, Stourbridge, England, DY9 8XJ
                </span>
              </h3>
            </div>
          </div>
        </div>
      </section>
      <div class="row">
        <div class="col-md-12 text-center">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2431.62880349905!2d-2.114293223075937!3d52.449638641436145!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48709114a910045d%3A0x90d141e349b09733!2s3%20Brook%20Holloway%2C%20Stourbridge%20DY9%208XJ!5e0!3m2!1sen!2suk!4v1703160734280!5m2!1sen!2suk"
            width="100%"
            title="map"
            height="450"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>

      <CarouselComponent />
    </div>
  );
}

export default ContactUs;
