import React from "react";
import Carousel from "react-multi-carousel";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
function CarouselComponent(props) {
  return (
    <section className="our_testimonials center-style float-left w-100">
      <div className="titleprof">
        <div className="container">
          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
              <h2>Professional Accreditations</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="test-left">
              <img src="assets/images/green.webp" alt="" />
              {/* <p>Reg. No NAPI51657</p> */}
              <p className="texta">
                *Cavity wall insulation including that installed in party walls,
                floor insulation, loft insulation (roll insulation & blown
                insulation), room in roof insulation, condensing boilers,
                natural gas fired and liquefied petroleum gas fired, heating
                controls, electric storage heaters
              </p>
            </div>
          </div>
          <div className="col-md-9">
            <Carousel
              autoPlay={true}
              infinite
              arrows={false}
              responsive={responsive}
            >
              <div class="over">
                <img
                  alt="logo1"
                  src="assets/images/logos/trust-mark-logo.png"
                />
              </div>
              <div class="over">
                <img
                  alt="logo2"
                  src="https://www.blueflamecertification.com/_template/img/logo.png"
                />
              </div>
              <div class="over">
                <img alt="logo3" src="assets/images/logos/gas-safe.png" />
              </div>
              {/* <div class="over">
                <img alt="logo4" src="assets/images/logos/bass-logo.png" />
              </div> */}
              {/* <div class="over">
                <img
                  class="logo-image"
                  alt="logo5"
                  src="assets/images/logos/stroma-logo.jpg"
                />
              </div> */}
              <div class="over">
                <img
                  class="logo-image"
                  alt="logo6"
                  src="assets/images/logos/quality-mark-logo.png"
                />
              </div>
              {/* <div class="over">
                <img
                  class="logo-image"
                  alt="logo6"
                  src="assets/images/logos/niceic-logo.png"
                />
              </div>
              <div class="over">
                <img
                  class="logo-image"
                  alt="logo6"
                  style={{ height: 100 }}
                  src="assets/images/logos/simply-logo.png"
                />
              </div> */}
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CarouselComponent;
