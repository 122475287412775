import React from "react";
import CarouselComponent from "../Components/CarouselComponent";
import Header from "../Components/Header";
import { useDocumentTitle } from "../Util/hooks/useDocumentTitle";

function LoftInsulation(props) {
  useDocumentTitle("Loft Insulation");
  return (
    <div>
      <div>
        <div class="header-bg">
          <div class="navbar-section-aboutinner">
            <Header />
            <section class="banner-section">
              <div class="container">
                <div class="row">
                  <div class="col-lg-7 col-md-12 col-sm-12">
                    <h1 class="header-txt" data-aos="fade-up">
                      Grants for free loft insulation{" "}
                    </h1>
                    <p class="faont_7">
                      <span class="color_11">
                        Available to UK homeowners, tenants, and families that
                        are trying to make ends meet as energy prices keep
                        rising.
                      </span>
                    </p>
                    <p class="faont_7">
                      <span class="color_11">
                        You have a strong possibility of being approved for the
                        grant, and it only takes a minute to find out!
                      </span>
                    </p>

                    <p class="listheader" data-aos="fade-right">
                      <svg
                        preserveAspectRatio="xMidYMid meet"
                        data-bbox="36 47 128 106"
                        viewBox="36 47 128 106"
                        height="200"
                        width="200"
                        xmlns="http://www.w3.org/2000/svg"
                        data-type="color"
                        role="presentation"
                        aria-hidden="true"
                      >
                        <defs>
                          <style>
                            {`
                                            #comp-l11xk6rv svg [data-color="1"] {
                                                fill: #FFFFFF;
                                            }`}
                          </style>
                        </defs>
                        <g>
                          <path
                            d="M136.701 58.951l15.34 15.323-66.836 66.777-37.247-37.207 15.34-15.328L85.205 110.4l51.496-51.449zm0-11.951L85.204 98.454 63.297 76.569 36 103.846 85.205 153 164 74.274 136.701 47z"
                            fill="#2F54DD"
                            data-color="1"
                          ></path>
                        </g>
                      </svg>
                      &nbsp;
                      <span>Free installation of loft insulation</span>
                    </p>
                    <p class="listheader" data-aos="fade-right">
                      <svg
                        preserveAspectRatio="xMidYMid meet"
                        data-bbox="36 47 128 106"
                        viewBox="36 47 128 106"
                        height="200"
                        width="200"
                        xmlns="http://www.w3.org/2000/svg"
                        data-type="color"
                        role="presentation"
                        aria-hidden="true"
                      >
                        <defs>
                          <style>
                            {`
                                            #comp-l11xk6rv svg [data-color="1"] {
                                                fill: #FFFFFF;
                                            }`}
                          </style>
                        </defs>
                        <g>
                          <path
                            d="M136.701 58.951l15.34 15.323-66.836 66.777-37.247-37.207 15.34-15.328L85.205 110.4l51.496-51.449zm0-11.951L85.204 98.454 63.297 76.569 36 103.846 85.205 153 164 74.274 136.701 47z"
                            fill="#2F54DD"
                            data-color="1"
                          ></path>
                        </g>
                      </svg>
                      &nbsp;
                      <span>
                        100% legal under the ECO4 programme of the UK government
                      </span>
                    </p>
                    <p class="listheader" data-aos="fade-right">
                      <svg
                        preserveAspectRatio="xMidYMid meet"
                        data-bbox="36 47 128 106"
                        viewBox="36 47 128 106"
                        height="200"
                        width="200"
                        xmlns="http://www.w3.org/2000/svg"
                        data-type="color"
                        role="presentation"
                        aria-hidden="true"
                      >
                        <defs>
                          <style>
                            {`
                                            #comp-l11xk6rv svg [data-color="1"] {
                                                fill: #FFFFFF;
                                            }`}
                          </style>
                        </defs>
                        <g>
                          <path
                            d="M136.701 58.951l15.34 15.323-66.836 66.777-37.247-37.207 15.34-15.328L85.205 110.4l51.496-51.449zm0-11.951L85.204 98.454 63.297 76.569 36 103.846 85.205 153 164 74.274 136.701 47z"
                            fill="#2F54DD"
                            data-color="1"
                          ></path>
                        </g>
                      </svg>
                      &nbsp;
                      <span>
                        Princeton Eco Plus's welcoming staff takes care of
                        everything for you.
                      </span>
                    </p>
                  </div>
                  <div class="col-lg-4 col-md-12 col-sm-12">
                    <div class="qa-faq" data-aos="fade-down">
                      <h2 class="fdaont_2">
                        Applying is <br />
                        <span>
                          <span class="green-light">
                            quick and <br />
                            stress free
                          </span>
                        </span>
                      </h2>
                      <div class="company_btn_wrapper">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          class="text-decoration-none learn_btn_company_stats"
                          href="https://docs.google.com/forms/d/e/1FAIpQLSd4e33mW5w0Uk_ySZ3OKX0XxS_6eh_fuawc6_bVU6xe-JRgtQ/viewform?usp=sf_link"
                        >
                          CHECK ELIGIBILITY
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <section>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-8">
              <div class="qa-faq">
                <div class="row">
                  <div class="col-md-9">
                    <h4>Got a question first? Visit our FAQs page</h4>
                  </div>
                  <div class="col-md-3">
                    <div class="farbtn">
                      <a href="/faqs" class="faq-btn">
                        FAQs
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="step-sec">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="step-box">
                <h2>THREE SIMPLE STEPS:</h2>
                <div class="row">
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon1.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        Take 60 seconds of your <br />
                        time to fill out the form
                      </h5>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon2.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        We’ll let you know whether <br />
                        you’re eligible for a grant
                      </h5>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon3.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        Our engineers will be in touch to <br />
                        book a survey and installation date
                      </h5>
                    </div>
                  </div>
                </div>
                <span>
                  No more days and nights spent battling the cold. Keep your
                  home nice and cosy and keep those energy bills low.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="about_us"
        style={{ backgroundColor: "#fff", paddingBottom: 0 }}
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_content">
                <h3 class="fsont__3" data-aos="fade-right">
                  <span>
                    <span>How do you know if you qualify for a </span>
                    <span class="green-light">free loft insulation grant?</span>
                  </span>
                </h3>

                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  Contacting someone is the only surefire way to find out. Fill
                  out our short and easy application form right now to receive a
                  prompt answer from our customer service team.
                </p>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  The standards are:{" "}
                </p>
                <ul class="font_7">
                  <li>
                    <p class="font_7">
                      Receiving income-based benefits or tax credits?
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      Homeowner or private tenant (if renting, you’ll need
                      landlord permission)?
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      Struggling to make ends meet as fuel prices and the cost
                      of living keep rising?
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      Are you a pensioner? You may qualify, so get in touch to
                      find out.
                    </p>
                  </li>
                </ul>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  If you're unsure, just fill out the form, and a member of the
                  Princeton Eco Plus customer care team will contact you to let
                  you know if you're eligible for the award.
                </p>

                <h2 class="font_122" data-aos="fade-right">
                  Usually, the roof of a house allows a third of the heat to
                  escape.{" "}
                </h2>

                <p class="font_7">
                  Put a stop to it (and save money) with the UK government’s{" "}
                  <span style={{ fontWeight: "bold" }}>
                    FREE LOFT INSULATION GRANT.
                  </span>
                </p>
                <p class="about_us_paragraph mb-0" data-aos="fade-right">
                  Free to apply Free installation. Effortless and simple from
                  front to end. Give the Princeton Eco Plus staff a call and
                  take a moment to apply.
                </p>
                <div class="company_btn_wrapper">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    class="text-decoration-none learn_btn_company_stats"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSd4e33mW5w0Uk_ySZ3OKX0XxS_6eh_fuawc6_bVU6xe-JRgtQ/viewform?usp=sf_link"
                  >
                    CHECK ELIGIBILITY
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_image" data-aos="fade-right">
                <figure class="mb-0">
                  <img src="assets/images/loft-insulation.jpg" alt="" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="about_us"
        style={{ backgroundColor: "#fff", paddingBottom: 0 }}
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_image" data-aos="fade-right">
                <figure class="mb-0">
                  <img src="assets/images/loft-insulation-2.jpg" alt="" />
                </figure>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_content">
                <h3 class="fsont__3" data-aos="fade-right">
                  <span>
                    <span>The issue of fuel poverty </span>
                    <span class="green-light">
                      is one that is getting worse.
                    </span>
                  </span>
                </h3>

                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  You'll have noticed how challenging it is getting to maintain
                  your home warm and cosy for yourself and your family if you
                  rely on income-based benefits to get by.
                </p>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  The government's FREE LOFT INSULATION programme is designed to
                  assist people like you in saving money on energy bills,
                  reducing their impact on the environment, and maintaining
                  their homes in a warm and livable condition because so much
                  heat is lost through roofs due to inadequate or missing
                  insulation.
                </p>

                <h2 class="font_122" data-aos="fade-right">
                  How does loft insulation contribute to lower heating costs?{" "}
                </h2>

                <p class="font_7">
                  By reducing heat loss via your roof, loft insulation improves
                  the thermal efficiency of your home. This implies:
                </p>
                <ul class="font_7">
                  <li>
                    <p class="font_7">
                      The moment you turn off your heaters or radiators, the
                      heat you produce won't instantly vanish.
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      You might reduce your annual energy costs by anything from
                      £120 and £280.
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      You'll lessen the carbon footprint of your household.{" "}
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      A warmer house and a higher level of living will be to
                      your advantage.
                    </p>
                  </li>
                </ul>
                <div class="company_btn_wrapper">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    class="text-decoration-none learn_btn_company_stats"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSd4e33mW5w0Uk_ySZ3OKX0XxS_6eh_fuawc6_bVU6xe-JRgtQ/viewform?usp=sf_link"
                  >
                    CHECK ELIGIBILITY
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="freesec">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-10">
              <div class="free-complete">
                <div class="row">
                  <div class="col-md-5">
                    <div class="fretxt">
                      <h3
                        class="fon12t_3 aos-init aos-animate"
                        data-aos="fade-right"
                      >
                        <span>
                          <span class="color_25">
                            It’s all down to the ECO4 scheme run by the{" "}
                          </span>
                          <span class="green-light">UK government </span>
                        </span>
                      </h3>

                      <div class="bor-bot aos-init" data-aos="fade-left"></div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <div class="freebox elec-hea">
                      <p class="font_147">
                        The programme is the government's attempt to increase
                        the country's energy efficiency while easing the burden
                        on homeowners and renters everywhere. Click here to
                        learn more about ECO4.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="about_us"
        style={{ backgroundColor: "#fff", paddingBottom: 0 }}
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_content">
                <h3
                  class="font_3"
                  style={{
                    fontSize: "30px",
                    lineHeight: "28px",
                    color: "#0c3e35",
                  }}
                  data-aos="fade-right"
                >
                  <span style={{ fontSize: "30px" }}>
                    <span style={{ color: "#0c3e35" }}>Am I </span>
                    <span class="green-light">eligible?</span>
                  </span>
                </h3>
                <p class="about_us_paragraph mb-0" data-aos="fade-right">
                  There have been some changes to past eligibility criteria,
                  with disability benefits no longer on the list. Check out the
                  full criteria for ECO4 funding below:
                </p>
                <ul class="font_7">
                  <li>
                    <p class="font_7">Child Tax Credits (CTC)</p>
                  </li>
                  <li>
                    <p class="font_7">Housing Benefit NEW</p>
                  </li>
                  <li>
                    <p class="font_7">
                      Income based Jobseeker’s Allowance (JSA)
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      Income related Employment &amp; Support Allowance (ESA)
                    </p>
                  </li>
                  <li>
                    <p class="font_7">Pension Credit Guarantee Credit</p>
                  </li>
                  <li>
                    <p class="font_7">Pension Credit Savings Credit NEW</p>
                  </li>
                  <li>
                    <p class="font_7">Universal Credit (UC)</p>
                  </li>
                  <li>
                    <p class="font_7">Warm Home Discount Scheme Rebate</p>
                  </li>
                  <li>
                    <p class="font_7">Working Tax Credit (WTC)</p>
                  </li>
                </ul>
                <p class="about_us_paragraph mb-0" data-aos="fade-right">
                  <strong>
                    Grants for loft insulation are now accepting applications.
                    Because space is limited, submit your application AS SOON AS
                    POSSIBLE.
                  </strong>
                </p>

                <div class="company_btn_wrapper">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    class="text-decoration-none learn_btn_company_stats"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSd4e33mW5w0Uk_ySZ3OKX0XxS_6eh_fuawc6_bVU6xe-JRgtQ/viewform?usp=sf_link"
                  >
                    CHECK ELIGIBILITY
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_image" data-aos="fade-right">
                <figure class="mb-0">
                  <img src="assets/images/atticfloor.webp" alt="" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="red-sec">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h5>
                Don't hang around waiting for someone to beat you to the punch.
                By submitting an application for the FREE LOFT INSULATION GRANT
                right away, you and your family can enjoy a warmer, more
                affordable, and cosier home.
              </h5>
            </div>
          </div>
        </div>
      </section>
      <CarouselComponent />
    </div>
  );
}

export default LoftInsulation;
