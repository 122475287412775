import React from "react";
import CarouselComponent from "../Components/CarouselComponent";
import Header from "../Components/Header";
import { useDocumentTitle } from "../Util/hooks/useDocumentTitle";

function CertifiedInsulationInstallers(props) {
  useDocumentTitle("Certified Insulation Installers");
  return (
    <div>
      <div class="header-bg">
        <div class="navbar-section-we_mv2">
          <Header />
          <section class="banner-section">
            <div class="container">
              <div class="row">
                <div class="col-lg-7 col-md-12 col-sm-12">
                  <h1 class="header-txt" data-aos="fade-up">
                    Princeton Eco Plus Ltd - Certified insulation installers
                  </h1>
                  <div class="qabout-faq">
                    <div class="row">
                      <div class="col-md-3">
                        <div class="qaboutbtn">
                          <a href="/faqs" class="qabout-btn">
                            FAQs
                          </a>
                        </div>
                      </div>
                      <div class="col-md-9">
                        <h4>
                          Got a question first?
                          <br />
                          Visit out FAQs page
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 mt-3">
                  <div class="qa-faq" data-aos="fade-down">
                    <h2 class="fdaont_2">
                      How efficient is
                      <br />{" "}
                      <span>
                        <span class="green-light">YOUR</span>
                      </span>{" "}
                      home?
                    </h2>
                    <div class="company_btn_wrapper">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        class="text-decoration-none learn_btn_company_stats"
                        href="https://docs.google.com/forms/d/e/1FAIpQLSd4e33mW5w0Uk_ySZ3OKX0XxS_6eh_fuawc6_bVU6xe-JRgtQ/viewform?usp=sf_link"
                      >
                        CHECK ELIGIBILITY
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <section class="step-sec-about mt-3">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="step-box">
                <h2 class="green-dark">THREE SIMPLE STEPS:</h2>
                <div class="row">
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon1.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        Take 60 seconds of your <br />
                        time to fill out the form
                      </h5>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon2.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        We’ll let you know whether <br />
                        you’re eligible for a grant
                      </h5>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon3.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        Our engineers will be in touch to <br />
                        book a survey and installation date
                      </h5>
                    </div>
                  </div>
                </div>
                <span>
                  No more days and nights spent battling the cold. Keep your
                  home nice and cosy and keep those energy bills low.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="about_us"
        style={{ backgroundColor: "#fff", paddingBottom: 0 }}
      >
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <img src="assets/images/we1_mv2.webp" width="100%" alt="" />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_content">
                <h3 class="fsont__3" data-aos="fade-right">
                  <span>
                    <span>Solid Wall </span>
                    <span class="green-light">Insulation</span>
                  </span>
                </h3>

                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  Has there ever been a cavity in your house? You can qualify
                  for financial assistance with interior wall insulation. There
                  are few choices available to homeowners with solid wall
                  properties to control heat loss and raise living standards,
                  but Princeton Eco Plus Ltd can assist. Contact us right away
                  to find out if you're eligible for a grant because we are
                  licenced installers of solid wall insulation.
                </p>
                <div class="qaboutbtn">
                  <a href="/solid-wall-insulation" class="qabout-btn">
                    Solid Wall
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        class="about_us"
        style={{ backgroundColor: "#fff", paddingBottom: 0 }}
      >
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_content">
                <h3 class="fsont__3" data-aos="fade-right">
                  <span>
                    <span>Fully Funded Cavity </span>
                    <span class="green-light">Wall Insulation</span>
                  </span>
                </h3>

                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  The specialists in cavity wall insulation at Princeton Eco
                  Plus Ltd. To help our customers save money on energy and
                  enhance thermal efficiency, our qualified staff insulates
                  homes around the nation. In collaboration with Energystore, we
                  provide completely financed cavity wall insulation to people
                  who need it the most.
                </p>
                <div class="qaboutbtn">
                  <a href="/cavity-wall" class="qabout-btn">
                    Cavity Wall
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <img src="assets/images/we3_mv2.webp" width="100%" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section
        class="about_us"
        style={{ backgroundColor: "#fff", paddingBottom: 0 }}
      >
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <img src="assets/images/we4_mv2.webp" width="100%" alt="" />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_content">
                <h3 class="fsont__3" data-aos="fade-right">
                  <span>
                    <span>Room-in- </span>
                    <span class="green-light">Roof insulation</span>
                  </span>
                </h3>

                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  If you've converted your attic or roof space, you may have
                  observed that room-in-roof spaces may be challenging to keep
                  cool in the summer and warm in the winter. Fortunately,
                  Princeton Eco Plus Ltd offers a group of knowledgeable and
                  licenced roof insulation installers that can support you in
                  finding a solution. If you qualify, we can make sure the
                  temperature in your roof area is comfortable all year long.
                </p>
                <div class="qaboutbtn">
                  <a href="/loft-insulation" class="qabout-btn">
                    Room-in-Roof
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        class="about_us"
        style={{ backgroundColor: "#fff", paddingBottom: 0 }}
      >
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_content">
                <h3 class="fsont__3" data-aos="fade-right">
                  <span>
                    <span>Government-Funded Loft </span>
                    <span class="green-light">Insulation</span>
                  </span>
                </h3>

                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  The fact that up to 25% of the heat produced within your home
                  is lost through the roof may surprise you. Loft insulation not
                  only helps your home retain heat more effectively, but it also
                  has the potential to drastically reduce your annual energy
                  expenses.
                </p>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  Get in touch with Princeton Eco Plus Ltd. immediately to see
                  if you qualify for government money focused at improving the
                  insulation and heating in your home.
                </p>
                <div class="qaboutbtn">
                  <a href="/loft-insulation" class="qabout-btn">
                    Loft Insulation
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <img src="assets/images/we5_mv2.webp" width="100%" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section class="red-sec">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h5>Are you eligible for funding?</h5>
            </div>
          </div>
        </div>
      </section>
      <CarouselComponent />
    </div>
  );
}

export default CertifiedInsulationInstallers;
